import { reloadSwiperWithNewSheet,closeMenu} from './index.js';  // Importujte funkciu z hlavného súboru
import { loadMedia } from './media.js';  // Importujte isMediaLoaded a loadMedia zo súboru media.js

const ownmedia = document.getElementById('ownmedia');
const svgLoaderButton = document.querySelector('.svgLoaderButton');
const closeButton = document.querySelector('.toggle-btnCloseMedia');


if (ownmedia) {
  ownmedia.addEventListener('click', function() {  
    if (closeButton) {
      closeButton.style.opacity = '1';
      closeButton.style.zIndex = '1002'; 
    }

    // Pridajte triedu pre animáciu
    svgLoaderButton.classList.add('rotateOnce');
    
    // Odstráňte triedu po skončení animácie
    svgLoaderButton.addEventListener('animationend', function() {
      svgLoaderButton.classList.remove('rotateOnce');
    }, { once: true }); 

    loadMedia();
  });
} else {
  console.error('ownmedia element not found!');
}

 // Funkcia pre prepínanie pomocou klávesy 'P'
 document.addEventListener('keydown', (event) => {
  if (event.key.toLowerCase() === 'c') {
    if (closeButton) {
      closeButton.style.opacity = '1';
      closeButton.style.zIndex = '1002'; 
    }

    // Pridajte triedu pre animáciu
    svgLoaderButton.classList.add('rotateOnce');
    
    // Odstráňte triedu po skončení animácie
    svgLoaderButton.addEventListener('animationend', function() {
      svgLoaderButton.classList.remove('rotateOnce');
    }, { once: true }); 

    loadMedia();
  } else {
    return false
  }
});

export function populateMenu(data) {
    svgLoaderButton.classList.remove('rotating');
    const menuDiv = document.getElementById("menu");
 console.log('datamenu', data);
    data.forEach((item,index) => {
       
        const menuItem = document.createElement("div");
        menuItem.className = "menu-item";
        
       
        menuItem.setAttribute('data-img-url', item.imgurl.value); // Uloženie URL do data atribútu
        const tags = item.tags?.value ? item.tags.value.split(',').map(tag => `<span>${tag.trim()}</span>`).join('') : "";

        menuItem.innerHTML = `<li><a href='#'>${item.name.value}</a></li><div class='tags'>${tags}</div>`;
         
        menuItem.addEventListener('click', function() {

          if (this.id !== 'ownmedia') {
            console.log(index);
            document.getElementById('toggle').checked = false; // Zrušenie zaškrtnutia toggle tlačidla
        }

            const sheetNameInput = document.getElementById("sheetNameInput");
            sheetNameInput.value = item.name.value;
            const mediaContainer = document.getElementById('media-container');
            mediaContainer.innerHTML = '';
            reloadSwiperWithNewSheet();
            closeMenu(); // Zatvorenie navigačného menu po kliknutí
           // document.getElementById('toggle').checked = false; // Zrušenie zaškrtnutia toggle tlačidla
           
        });
        
        menuDiv.appendChild(menuItem);
    });

    // Funkcia pre načítanie obrázkov
    const loadImages = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const menuItem = entry.target;
                const imgUrl = menuItem.getAttribute('data-img-url');
                
                // Nastav pozadie obrázku
                menuItem.style.backgroundImage = `url(${imgUrl})`;
                menuItem.style.backgroundSize = 'cover'; // Prispôsobenie obrázka
                menuItem.style.backgroundPosition = 'center'; // Stredové umiestnenie obrázka
				
				// Pridaj triedu loaded pre fade-in efekt
                menuItem.classList.add('loaded');
                
                observer.unobserve(menuItem); // Prestane sledovať, keď je obrázok načítaný
            }
        });
    };

    // Vytvoríme nový IntersectionObserver
    const observer = new IntersectionObserver(loadImages);

    // Pridaj pozorovateľ pre každý položku menu
    const menuItems = document.querySelectorAll('.menu-item');
    menuItems.forEach(item => {
        observer.observe(item);
    });


  
}
