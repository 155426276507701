import { closeMenu } from './index.js';  // Importujte funkciu z hlavného súboru

export let isMediaLoaded = true;
let isLoadMediaActive = true;  // Globálna premenná na sledovanie stavu
let observer;  // Premenná pre IntersectionObserver

// Funkcia na skrytie media-container
function hideMediaContainer() {
  const mediaContainer = document.getElementById('media-container');
  
  
  if (mediaContainer) {
    mediaContainer.style.visibility = 'hidden';
    mediaContainer.innerHTML = ''; // Ak chcete odstrániť obsah, pridajte tento riadok
  } else {
    console.error('mediaContainer element not found');
  }
}

// Funkcia na zobrazenie media-container
function showMediaContainer() {
  const mediaContainer = document.getElementById('media-container');
  if (mediaContainer) {
    mediaContainer.style.visibility = 'visible';
  } else {
    console.error('mediaContainer element not found');
  }
}

export function loadMedia() {
  if (!isLoadMediaActive) return;  // Skontrolujeme, či má funkcia pokračovať

  closeMenu(); // Zatvorenie navigačného menu po kliknutí
  document.getElementById('toggle').checked = false; // Zrušenie zaškrtnutia toggle tlačidla
  const perPage = 100; // Nastavíme limit na 50 položiek na stránku
  let page = 1; // Začneme s prvou stránkou
  let isLoading = false; // Flag na sledovanie načítania
  const mediaContainer = document.getElementById('media-container');
  const ownmedia = document.getElementById('ownmedia');

  const baseUrl = `https://www.divart.sk/wp-json/wp/v2/media?per_page=${perPage}&page=`;

  function showLoadingIndicator() {
    let loadingIndicator = document.getElementById('loading-indicator');
    let forfullsreen = document.getElementById('forfullsreen');
    if (!loadingIndicator) {
      loadingIndicator = document.createElement('div');
      loadingIndicator.id = 'loading-indicator';
      loadingIndicator.className = 'loading-indicator';
      loadingIndicator.textContent = 'Loading media...click on image to get url';
      forfullsreen.appendChild(loadingIndicator);
    }
    loadingIndicator.style.display = 'block';
  }

  function hideLoadingIndicator() {
    const loadingIndicator = document.getElementById('loading-indicator');
    if (loadingIndicator) {
      loadingIndicator.style.display = 'none';
    }
  }

  function fetchMedia() {
    if (!isLoadMediaActive || isLoading) return; // Skontrolujeme stav pred načítaním
    isLoading = true; // Nastavíme flag načítania
    showLoadingIndicator();

    const url = `${baseUrl}${page}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.length > 0) {
          displayMedia(data); // Zobrazíme nové médiá
          page++; // Pripravíme sa na ďalšiu stránku
        }
        isLoading = false; // Ukončíme flag načítania
        hideLoadingIndicator();
      })
      .catch(error => {
        console.error('Error fetching media:', error);
        isLoading = false; // Ukončíme flag načítania aj pri chybe
        hideLoadingIndicator();
      });
  }

  function displayMedia(mediaArray) {
    console.log(mediaArray)
    if (!mediaContainer) {
      console.error('No media container found!');
      return;
    }

    mediaArray.forEach(media => {
      if (!media || !media.source_url || !media.media_type) {
        console.error('Invalid media item:', media);
        return;
      }

      const mediaElement = document.createElement('div');
      mediaElement.classList.add('media-item');

      let hasContent = false; // Flag na kontrolu, či sa pridalo médium



      switch (media.media_type) {
        case 'image':
          const img = document.createElement('img');
          const thumbnailUrl = media.media_details.sizes.thumbnail ? media.media_details.sizes.thumbnail.source_url : media.source_url;
          img.src = thumbnailUrl;
          img.alt = media.alt_text || 'Image';
          img.loading = 'lazy'; // Pridanie lazy loading
          img.dataset.fullsize = media.source_url; // Uložíme URL originálneho obrázka do datasetu
          img.addEventListener('click', function() {
            // Skopíruj URL originálneho obrázka do schránky
            navigator.clipboard.writeText(this.dataset.fullsize)
              .then(() => {
                // Zobrazíme animáciu po kliknutí
                showCopiedText(this);
              })
              .catch(err => {
                console.error('Failed to copy image URL:', err);
              });
          });
          mediaElement.appendChild(img);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

        case 'video':
          const video = document.createElement('video');
          video.src = media.source_url;
          video.controls = true;
          video.loading = 'lazy'; // Pridanie lazy loading
          mediaElement.appendChild(video);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

        case 'audio':
          // cccccccc
       
          const audio = document.createElement('audio');
          audio.src = media.source_url;
          audio.controls = true;
          audio.loading = 'lazy'; // Pridanie lazy loading
          mediaElement.appendChild(audio);
          hasContent = true; // Nastavíme flag, že sa pridalo médium
          break;

          case 'application':
            case 'file':
                const fileExtension = media.source_url.split('.').pop().toLowerCase();
                
                // Vytvorenie div elementu pre médium
                const mediaDiv = document.createElement('div');
                 
                mediaDiv.classList.add('anotherMediaItem');
                // Pridanie názvu súboru
                const titleText = document.createElement('div');
                titleText.style.color = 'white'; // Biela farba textu
                titleText.textContent = media.title.rendered || 'Unknown File';
                mediaDiv.appendChild(titleText);
            
                // Pridanie typu súboru
                const typeText = document.createElement('div');
                typeText.style.color = 'white'; // Biela farba textu
                typeText.textContent = fileExtension.toUpperCase(); // Typ súboru
                mediaDiv.appendChild(typeText);
            
                // Pridanie event listenera pre kliknutie na div
                mediaDiv.addEventListener('click', function() {
                    const urlToCopy = media.source_url;
                    navigator.clipboard.writeText(urlToCopy)
                        .then(() => {
                            // Zobrazíme animáciu po kliknutí
                            showCopiedText(this);
                        })
                        .catch(err => {
                            console.error('Failed to copy URL:', err);
                        });
                });
                
                mediaElement.appendChild(mediaDiv);
                hasContent = true; // Nastavíme flag, že sa pridalo médium
                break;
            
            
        default:
         // console.error('Unknown media type:', media);
      }

      // Ak sa do mediaElement pridalo médium, pridáme ho do kontajnera
      if (hasContent) {
        mediaContainer.appendChild(mediaElement);
      }
    });

    // Po pridaní nových médií aktualizujeme pozorovateľa
    updateObserver();
  }

  function showCopiedText(element) {
    // Vytvoríme element na zobrazenie textu
    const copiedText = document.createElement('div');
    copiedText.className = 'copied-text';
    copiedText.textContent = 'Copied!';
  
    // Umiesťujeme text na rovnakú pozíciu ako element
    element.parentElement.appendChild(copiedText);
  
    // Odstránime text po 2 sekundách
    setTimeout(() => {
      copiedText.remove();
    }, 2000);
  }
  
  

  // Vytvoríme IntersectionObserver na načítanie ďalších dát pri scrollovaní
  observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
      fetchMedia(); // Načítame ďalšie médiá
    }
  }, {
    rootMargin: '100px', // Skúsiť načítať o 100px skôr, než je viditeľné
  });

  function updateObserver() {
    if (mediaContainer && mediaContainer.lastElementChild) {
      observer.observe(mediaContainer.lastElementChild);
    }
  }

  if (isMediaLoaded) {
    const removeSwiper = document.getElementById('wrapper');
    removeSwiper.innerHTML = '';
    closeMenu();

    page = 1;
    fetchMedia();
    if (mediaContainer) {
      mediaContainer.innerHTML = '';
    } else {
      console.error('mediaContainer element not found');
    }
  }
}

document.addEventListener('DOMContentLoaded', function() {
  const mediaContainer1 = document.getElementById('media-container');
  const toggleButtonINMEDIA = document.getElementById('toggle');

  // Toggle button event listener
  toggleButtonINMEDIA.addEventListener('click', function() {
    hideMediaContainer(); // Skryjeme media-container
   
  });

  // Počkáme, kým sa stránka načíta, a potom pripojíme listener
  const ownmedia = document.getElementById('ownmedia');
  if (ownmedia) {
    ownmedia.addEventListener('click', function() {
      isLoadMediaActive = true; // Nastavíme flag na true
      showMediaContainer(); // Zobrazíme media-container
      loadMedia(); // Znova načítame médiá
      console.log('clicked')
    });
  } else {
    console.error('ownmedia element not found');
  }
});
